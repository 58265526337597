import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppComponent } from './wcam-app.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/features.module').then((m) => m.FeaturesModule),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [RouterModule.forRoot(routes), CoreModule, SharedModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
